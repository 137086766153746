const state = {
  lessonList: [],
  lessonDate: [],
  applyLessonDic: {},
  bookDate: {},
}
const getters = {
  lessonList: state => state.lessonList,
  lessonDate: state => state.lessonDate,
  applyLessonDic: state => state.applyLessonDic,
  bookDate: state => state.bookDate,
}
const mutations = {
  updateList(state, payload) {
    state.lessonList = payload
  },
  updateLessonDate(state, payload) {
    state.lesslessonDateonList = payload
  },
  updateApplyLessonDic(state, payload) {
    state.applyLessonDic = payload
  },
  updateBookDate(state, payload) {
    state.bookDate = payload
  },
}
const actions = {
  updateList({ commit }, payload) {
    commit('updateList', payload)
  },
  updateLessonDate({ commit }, payload) {
    commit('updateLessonDate', payload)
  },
  updateApplyLessonDic({ commit }, payload) {
    commit('updateApplyLessonDic', payload)
  },
  updateBookDate({ commit }, payload) {
    commit('updateBookDate', payload)
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

const state = {
  payInfoDic: {},
  payHistoryList: [],
}
const getters = {
  payInfoDic: state => state.payInfoDic,
  payHistoryList: state => state.payHistoryList,
}
const mutations = {
  updateDic(state, payload) {
    state.payInfoDic = payload
  },
  updateList(state, payload) {
    state.payHistoryList = payload
  },
}
const actions = {
  updateDic({ commit }, payload) {
    commit('updateDic', payload)
  },
  updateList({ commit }, payload) {
    commit('updateList', payload)
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

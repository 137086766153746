const state = {
  verifyNumber: '',
  verifyEmailStatus: 1,
  mailaddress: '',
}
const getters = {
  verifyNumber: state => state.verifyNumber,
  verifyEmailStatus: state => state.verifyEmailStatus,
  mailaddress: state => state.mailaddress,
}
const mutations = {
  resetVerifyNumber(state) {
    state.verifyNumber = ''
  },
  updateVerifyEmailStatus(state, payload) {
    state.verifyEmailStatus = payload
  },
  updateMailaddress(state, payload) {
    state.mailaddress = payload
  },
}
const actions = {
  updateVerifyEmailStatus({ commit }, payload) {
    commit('updateVerifyEmailStatus', payload)
  },
  updateMailaddress({ commit }, payload) {
    commit('updateMailaddress', payload)
  },
  resetVerifyNumber({ commit }) {
    commit('resetVerifyNumber')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
